@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@import 'node_modules/lightcase/src/scss/lightcase';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

//MIXINS
@mixin sectionpadding {
  padding:50px 0;
}
@mixin vertical-align {
  position:absolute;
  width:100%;
  left:0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// animation element (on scroll animations)
.animation-element {
	opacity: 0;
	position: relative;

	&.slide-left {
        -webkit-transition: all 800ms ease;
        -moz-transition: all 800ms ease;
        -o-transition: all 800ms ease;
        transition: all 800ms ease;
        opacity: 0;
        -moz-transform: translate3d(-30px, 0px, 0px);
        -webkit-transform: translate3d(-30px, 0px, 0px);
        -o-transform: translate(-30px, 0px);
        -ms-transform: translate(-30px, 0px);
        transform: translate3d(-30px, 0px, 0px);

        @include breakpoint(small down) {
            transform: translate3d(0px, 30px, 0px);
             -moz-transform: translate3d(0px, 30px, 0px);
            -webkit-transform: translate3d(0px, 30px, 0px);
        }
    }

    &.slide-left-rotate {
        -webkit-transition: all 2s ease;
        -moz-transition: all 2s ease;
        -o-transition: all 2s ease;
        transition: all 2s ease;
        opacity: 0;
        -moz-transform: translate3d(-100px, 0px, 0px);
        -webkit-transform: translate3d(-100px, 0px, 0px);
        -o-transform: translate(-100px, 0px);
        -ms-transform: translate(-100px, 0px);
        transform: translate3d(-100px, 0px, 0px) rotate(200deg);
        @include breakpoint(small down) {
            transform: translate3d(0px, 100px, 0px);
             -moz-transform: translate3d(0px, 100px, 0px);
            -webkit-transform: translate3d(0px, 100px, 0px);
        }
    }

	&.slide-right {
        -webkit-transition: all 800ms ease;
        -moz-transition: all 800ms ease;
        -o-transition: all 800ms ease;
        transition: all 800ms ease;
        opacity: 0;
        -moz-transform: translate3d(30px, 0px, 0px);
        -webkit-transform: translate3d(30px, 0px, 0px);
        -o-transform: translate(30px, 0px);
        -ms-transform: translate(30px, 0px);
        transform: translate3d(30px, 0px, 0px);

        @include breakpoint(small down) {
            transform: translate3d(0px, 30px, 0px);
             -moz-transform: translate3d(0px, 30px, 0px);
            -webkit-transform: translate3d(0px, 30px, 0px);
        }
    }

    &.slide-up {
        opacity: 0;
        transform: translate3d(0px, 30px, 0px);
        -moz-transform: translate3d(0px, 30px, 0px);
        -webkit-transform: translate3d(0px, 30px, 0px);
        transition: all 600ms ease !important;
        -webkit-transition: all 600ms ease;
        -moz-transition: all 600ms ease;
        -o-transition: all 600ms ease;
    }

    &.slide-down {
        opacity: 0;
        transform: translate3d(0px, -30px, 0px);
        -moz-transform: translate3d(0px, -30px, 0px);
        -webkit-transform: translate3d(0px, -30px, 0px);
        transition: all 600ms ease;
        -webkit-transition: all 600ms ease;
        -moz-transition: all 600ms ease;
        -o-transition: all 600ms ease;
    }

    &.reveal-scroll {
        opacity: 0;
        transition: all 300ms ease;
        -webkit-transition: all 400ms ease;
        -moz-transition: all 400ms ease;
        -o-transition: all 400ms ease;

        &.reveal-second {
            transition-delay: 400ms;
        }

        &.reveal-third {
            transition-delay: 600ms;
        }
    }
    &.scale-up {
      -webkit-transition: all 800ms ease;
      -moz-transition: all 800ms ease;
      -o-transition: all 800ms ease;
      transition: all 800ms ease;
      opacity: 0;
      -moz-transform: scale(0,0);
      -webkit-transform: scale(0,0);
      -o-transform: scale(0,0);
      -ms-transform: scale(0,0);
      transform: scale(0,0);
  }

	&.slide-left.in-view, &.slide-right.in-view, &.slide-up.in-view, &.slide-down.in-view, &.reveal-scroll.in-view {
        opacity: 1;
        -moz-transform: translate3d(0px, 0px, 0px);
        -webkit-transform: translate3d(0px, 0px, 0px);
        -o-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        transform: translate3d(0px, 0px, 0px);
    }

    &.slide-left-rotate.in-view {
        transform:rotate(0deg) ;
        opacity:1;
    }
    &.scale-up.in-view {
      transform:scale(1,1);
      opacity:1;
    }
}

    //COOKIEWARNING STYELS
    #cookiesplease {
      background-color: get-color(primary);
      color: black;
      z-index:50000;

      display:block;
      .cookiesplease-decline {
        background-color: transparent;
        border:1px solid get-color(primary);
        color:rgba(white, .5);
        text-transform: uppercase;

      }
      .cookiesplease-accept {
          background-color: get-color(secondary);
          color:white;
          text-transform: uppercase;
          font-weight: 700;
          border-radius: 5px;
      }
    }
    .cookiesplease button {
        line-height:25px !important;
        border:none;
    }
    .cookiesplease p {
        color:black;
        text-align: center !important;
        display:block !important;
        max-width:100% !important;
    }
    .cookiesplease a {
      color:white;
    }

  //GENERIC STYLES


  /*
   * Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
   *
   * [1] IE9
   * [2] IE10+
   */
  /* 1 */
  .ie9 img[src*=".svg"].svgimage {
    width: 100%;
  }
  /* 2 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img[src*=".svg"].svgimage {
      width: 100%;
    }
  }

  button:focus, button:active, button:hover, button:visited, button:link {outline:0;} /* to remove blue border in chrome */
  a:focus, a:active, a:hover, a:visited, a:link {outline:0;} /* to remove blue border in chrome */

  //WORKAROUND FLASH OF UNSTYLED CONTENT
.no-js {
    @include breakpoint(small only) {
      .top-bar {
        display: none;
      }
    }

    @include breakpoint(medium) {
      .title-bar {
        display: none;
      }
    }
  }

  //GENERIC STYLES
  body,html {
      height:100%;
      position:relative;
  }
  a.button {
      text-transform: uppercase;
  }
  h1,h2,h3,h4,h5,h6 {
    text-transform: uppercase;
    color:get-color(primary);
  }

  //HEADER
  header {
      background-color: get-color(secondary);
      padding-top: 25px;
      padding-bottom: 25px;
      nav {
        .top-bar-left {
          li {
            border-right:1px solid get-color(primary);
            &:last-of-type{
              border-right:0px;
            }
            a {
              font-size: .8em;
              color:white;
              text-transform: uppercase;
              padding:0 1em;
              &:hover, &.active  {
                color:get-color(primary);
              }
              margin-bottom: 10px;
              @include breakpoint(large){
                margin-bottom: 0;
              }
            }
          }
        }
      }
  }

  //HOMEINTRO (SLIDER)
  #HomeIntro {
    height: 90vh;
    width: 100vw;
    background-color: get-color(secondary);
    display:flex;
    .backstretch {
      opacity: .65;
    }
    #introBaseline {
      margin: auto;
      padding:25px;
      h1 {
        border:4px solid white;
        color:white;
        padding: 25px;
        text-align: center;
      }
    }
  }

  //SHORTCUTS
  #shortCuts,  {
    @include sectionpadding;
    background-color: get-color(secondary);
    .shortcutItem {
      text-align: center;
      h2 {
        color: get-color(primary);
      }
      p {
        color:white;
      }
      a.button {
        border-color:white;
        color:white;
      }
    }
  }

  //NEWSSLIDER
  #newsSlider {
    @include sectionpadding;

    ul.orbit-container {
      border-right:4px solid get-color(primary);
      border-left:4px solid get-color(primary);
      li {
        padding:0 50px;
        img {
          width: 100%;
          height: auto;
          margin-bottom: 25px;
          @include breakpoint(large){
            margin-bottom: 0;
          }
        }
        a.button {
          margin-bottom: 0;
        }
      }


    }
    .orbit-previous {
      font-weight: bold;
      position: absolute;
      top: 50%;
      height:55px;
      width: 55px;
      margin-left:-25px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 4;
      padding: 0rem;
      color: get-color(primary);
      background-color: white;
      border-radius:50%;
      border:4px solid get-color(primary);
      font-size: 3rem;
      padding-top: 0px;
      padding-bottom:10px;
      padding-left:15px;
      padding-right: 15px;
      line-height: 25px;
      &:hover  {
        background-color: get-color(primary);
        color:white;
        opacity: 1;
      }
      &:active, &:visited {
        background-color: white;
        color:get-color(primary);
        opacity:1;
      }
    }
    .orbit-next {
      font-weight: bold;
      position: absolute;
      top: 50%;
      height:55px;
      width: 55px;
      margin-right:-25px;
      z-index: 4;
      font-size: 3rem;
      padding-top: 0px;
      padding-bottom:10px;
      padding-left:15px;
      padding-right: 15px;
      line-height: 25px;
      color: get-color(primary);
      background-color: white;
      border-radius:50%;
      border:4px solid get-color(primary);
      &:hover  {
        background-color: get-color(primary);
        color:white;
        opacity: 1;
      }
      &:active, &:visited {
        background-color: white;
        color:get-color(primary);
        opacity:1;
      }
    }
  }

  //CALLTOACTION
  #callToAction {
    padding-top: 75px;
    padding-bottom: 75px;
    background-color: get-color(primary);
    background-image: url('../img/cta_bg.svg');
    background-repeat: no-repeat;;
    background-position: center top;
    background-size: cover;
    text-align: center;
    * {
      color:white;
    }
    h2 {
      color:white;
      text-transform: initial;
      span {
        color:$body-font-color;
      }
    }

    a.button {
      border-color:white;
      color:white;
    }
  }

  //FEATURED REFERENCES
  #featuredReferences {
    @include sectionpadding;
    img {
      margin-bottom: 25px;
      width: 100%;
      height:auto;
    }
    p {
      font-weight: bold;
    }
  }

  //FOOTER
  footer {
    @include sectionpadding;
    background-color: get-color(secondary);
    img {
      margin-bottom: 25px;
      @include breakpoint(large) {
        margin-bottom: 0;
      }
    }
    p {
      font-weight: bold;
      color:white;
      @include breakpoint(large) {
        margin-bottom: 0;
      }
    }
    a {
      font-weight: normal;
      color:white;
      &:hover, &:visited, &:focus {
        color:white;
      }
      &.socialIcon {
        margin-left: 15px;
        img {
          margin-bottom: 0;
        }
      }
    }
    .footerLinks {
      @include breakpoint(large) {
        text-align: right;
      }
    }
  }

  //PAGEINTRO
  #pageIntro {
    height: 35vh;
    width: 100vw;
    position:relative;
    background-color: get-color(secondary);
    display:flex;
    #pageIntroBg {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity:.65;
    }
    #introBaseline {
      position:relative;
      z-index:1;
      margin: auto;
      padding:25px;
      h1 {
        border:4px solid white;
        color:white;
        padding: 25px;
        text-align: center;
      }
    }
  }

  //MAIN - COMPONENTS
  main {
    @include sectionpadding;

    h2 {
      color:get-color(primary);
      text-align: center;
    }
    img {
      width:100%;
      height: auto;
    }
    &.inverse {
      background-color: get-color(secondary);
      * {
        color:white;
      }
      a {
        color:get-color(primary);
      }
      h2 {
        color:get-color(primary);
      }
      a.button {
        color:white;
        border-color:white;
      }

    }
    &.textImageComponent {
      img {
        margin-bottom: 25px;
        @include breakpoint(medium) {
          margin-bottom: 0;
        }
      }
    }
    &.shortCutsComponent {
      .shortcutItem {
        text-align: center;
        h2 {
          color: get-color(primary);
        }
      }
    }
    &.listComponent {
      ol {
        list-style: none;
        counter-reset: my-awesome-counter;
        list-style-position: outside;
        li {
          counter-increment: my-awesome-counter;
          font-weight: bold;
          &:before {
            content: counter(my-awesome-counter) ". ";
            color: get-color(primary);
            font-weight: bold;
            margin-right: 15px;
          }
        }
      }
      img {
        margin-top: 25px;
      }
    }
  }

  //REFERENCEHOLDER
  #referenceList {
    @include sectionpadding;
    background-color: get-color(secondary);
    .referenceListItem {
      background-color: white;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      img {
        width: 100%;
        height:auto;
      }
      .energy {
        padding:30px;
        background-color: get-color(primary);
        * {
          color:white;
          font-weight: bold;
        }
      }
      .extra {
        background-color: get-color(secondary);
        background-image: url('../img/reflist_item_bg.svg');
        background-repeat: no-repeat;
        background-position: right top;
        background-size: cover;
        padding: 30px;
        * {
          color:white;
          font-weight: bold;
        }
        a.button {
          border-color: white;
        }
      }
    }
  }

  //LIGHTCASE OVERRIDES
  $lightcase-custom: (
    'overlay-color': get-color(secondary),
  );

  //CONTACTPAGE
  form {

    label {
      float: left;
      @include breakpoint(large) {
        width: 15%;
        margin-right:5%;
      }
      line-height: 1rem;

    }
    input, textarea {
      float: left !important ;
      @include breakpoint(large) {
        width: 70% !important ;
        margin-left: 10% !important;
      }
      background-color: #F0F0F0 !important;
      border:none !important;
      box-shadow: none !important;

    }
    input[type=submit] {
      clear:both;
      margin-left:0;
      background-color: white !important;
      color: get-color(primary);
      border: 1px solid get-color(primary) !important;
      @include breakpoint(large){
        margin-left:30% !important;
        margin-right:15px;
      }
      width:auto !important;
      padding:5px 10px;
    }
    input[type=checkbox] {
      width:auto !important;
      margin-left: 0 !important;
    }
    .g-recaptcha {
      float:left;
      @include breakpoint(large){
        margin-left:30%;
      }
      margin-bottom: 15px;
      width:70%;

    }
    .error {
      color:red;
    }
  }

  //REFERENCEPAGE
  .refDetails {
    padding:50px !important;
  }
  .specs {
    background-color: get-color(primary);
    padding:50px !important;
  }
  .video {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
